import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../../partials/controls";
import SearchSelect from "react-select";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  getOneEDMHeaderFooter,
  addNewEDMHeaderFooter,
  updateEDMHeaderFooter,
} from "../../../../service/settings/edmHeaderFooter";
import { DevURL } from "../../../../common/constant/defalut";
import { LOCAL_STORAGE_KEYS } from "../../../../common/constant/defalut";
import { getAllCompanies } from "../../../../service/companyService";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
import Loader from "../../../../common/loader";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { styles } from "./ckStyle";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";
import juice from "juice";
import "react-quill/dist/quill.snow.css";

const AddAPIKey = () => {
  const history = useHistory();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const params = useParams();
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [companyList, setCompanyList] = useState([]);

  const getCompanyList = () => {
    getAllCompanies()
      .then((res) => {
        let optionVal = res.data.data.map((x) => {
          return {
            label: x?.name,
            value: x?.name,
          };
        });
        setCompanyList(optionVal);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    if (params.id) {
      getOneKeyDetails();
    }
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOneKeyDetails = () => {
    setLoader(true);
    getOneEDMHeaderFooter(params?.id)
      .then((res) => {
        setLoader(false);
        setData(res?.data);
        setIsSwitchOn(Boolean(res?.data?.status === "ACTIVE"));
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const onUpdate = (value) => {
    setLoader(true);
    const requestBody = {};
    requestBody["name"] = value.name;
    requestBody["companyId"] = value.companyId;
    requestBody["header_content"] = juice.inlineContent(
      value.header_content,
      styles
    );
    requestBody["footer_content"] = juice.inlineContent(
      value.footer_content,
      styles
    );
    requestBody["status"] = isSwitchOn === true ? "ACTIVE" : "INACTIVE";
    updateEDMHeaderFooter(params.id, requestBody)
      .then((res) => {
        setLoader(false);

        toast.success("EDM Header & Footer update Successful");
        history.push("/setting/edm-header-footer");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response.data.message);
      });
  };

  const onSubmit = (value) => {
    setLoader(true);
    const requestBody = {};
    requestBody["name"] = value.name;
    requestBody["companyId"] = value.companyId;
    requestBody["header_content"] = juice.inlineContent(
      value.header_content,
      styles
    );
    requestBody["footer_content"] = juice.inlineContent(
      value.footer_content,
      styles
    );
    requestBody["status"] = isSwitchOn === true ? "ACTIVE" : "INACTIVE";

    addNewEDMHeaderFooter(requestBody)
      .then((res) => {
        setLoader(false);
        toast.success("EDM Header Footer Add Successful");
        history.push("/setting/edm-header-footer");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response.data.message);
      });
  };

  const BranchSchema = Yup.object().shape({
    // status: Yup.string().required("status is required"),
    phone: Yup.number(),
  });

  const formik = useFormik({
    initialValues: {
      name: params.id ? data?.name : "",
      companyId: params.id ? data?.companyId : "",
      header_content: params.id ? data?.header_content : "",
      footer_content: params.id ? data?.footer_content : "",
    },
    validationSchema: BranchSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      params.id ? onUpdate(values) : onSubmit(values);
    },
  });

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const config = {
    imageUploadParam: "file",
    htmlExecuteScripts: true,
    imageUploadMethod: "POST",
    imageUploadURL: `${DevURL}media/upload`,
    requestHeaders: {
      Authorization:
        localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "",
    },
    placeholderText: "Edit Your Content Here!",
    pluginsEnabled: [
      "align",
      "charCounter",
      "codeBeautifier",
      "codeView",
      "colors",
      "draggable",
      "emoticons",
      "entities",
      "fontFamily",
      "fontSize",
      "fullscreen",
      "help",
      "image",
      "inlineClass",
      "inlineStyle",
      "link",
      "lists",
      "paragraphFormat",
      "paragraphStyle",
      "print",
      "quote",
      "save",
      "specialCharacters",
      "table",
      "url",
      "video",
      "wordPaste",
    ],

    toolbarButtons: {
      moreText: {
        buttons: [
          "bold",
          "italic",
          "underline",
          "strikeThrough",
          "subscript",
          "superscript",
          "fontFamily",
          "fontSize",
          "textColor",
          "backgroundColor",
          "inlineClass",
          "inlineStyle",
          "clearFormatting",
        ],
        buttonsVisible: 3,
      },
      moreParagraph: {
        buttons: [
          "alignLeft",
          "alignCenter",
          "alignRight",
          "alignJustify",
          "outdent",
          "indent",
          "formatOL",
          "formatUL",
          "paragraphFormat",
          "paragraphStyle",
          "quote",
        ],
        buttonsVisible: 6,
      },
      moreRich: {
        buttons: [
          "insertLink",
          "insertImage",
          "insertVideo",
          "insertTable",
          "emoticons",
          "specialCharacters",
          "insertHR",
        ],
        buttonsVisible: 4,
      },
      moreMisc: {
        buttons: [
          "undo",
          "redo",
          "fullscreen",
          "print",
          "getPDF",
          "selectAll",
          "html",
          "help",
          "save",
        ],
        align: "right",
        buttonsVisible: 2,
      },
    },
  };

  return (
    <div>
      <Card>
        {loader && <Loader />}
        <CardHeader
          title={
            params?.id ? "Edit EDM Header & Footer" : "New EDM Header & Footer"
          }
        ></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Name</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    disabled={Boolean(params.id)}
                    className="form-control"
                    id="title"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Company</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <SearchSelect
                    options={companyList}
                    value={companyList.find(
                      (x) => x.value === formik.values.companyId
                    )}
                    onChange={(opt) => {
                      if (opt) {
                        formik.setFieldValue("companyId", opt.value);
                      } else {
                        formik.setFieldValue("companyId", "");
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 ml-3 ws-nowrap">Header Content</div>
            <div className="col-10 ml-3">
              {/* <CKEditor
                editor={DecoupledcEditor}
                config={{
                  extraPlugins: [uploadPlugin],
                }}
                className="ck"
                data={formik.values.header_content || ""}
                onReady={(editor) => {
                  editor.ui
                    .getEditableElement()
                    .parentElement.append(editor.ui.view.toolbar.element);
                }}
                onChange={handleHeaderChange}
                onBlur={() => formik.setFieldTouched("header_content", true)}
              /> */}
              <FroalaEditor
                model={formik.values.header_content}
                config={config}
                onModelChange={(eContent) => {
                  formik.setFieldValue("header_content", eContent);
                }}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-4 ml-3 ws-nowrap">Footer Content</div>
            <div className="col-10 ml-3">
              {/* <CKEditor
                editor={DecoupledcEditor}
                config={{
                  extraPlugins: [uploadPlugin],
                }}
                className="ck"
                data={formik.values.footer_content || ""}
                onReady={(editor) => {
                  editor.ui
                    .getEditableElement()
                    .parentElement.append(editor.ui.view.toolbar.element);
                }}
                onChange={handleFooterChange}
                onBlur={() => formik.setFieldTouched("footer_content", true)}
              /> */}

              <FroalaEditor
                model={formik.values.footer_content}
                config={config}
                onModelChange={(eContent) => {
                  formik.setFieldValue("footer_content", eContent);
                }}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-sm-3 mb-4 mb-sm-0 ws-nowrap d-flex justify-content-between align-items-center">
              <div className="col-lg-4 ws-nowrap ">Status</div>
              <FormControlLabel
                control={
                  <Switch
                    checked={isSwitchOn}
                    onChange={onSwitchAction}
                    color="primary"
                    name="enabled"
                  />
                }
                label={isSwitchOn ? "Active" : "Inactive"}
                className="mb-0"
              />
            </div>
          </div>
        </CardBody>

        <CardFooter>
          <div className="row">
            <div className="col-lg-10">
              <div className="d-flex flex-wrap align-items-center justify-content-end">
                <div className="mt-4 mt-sm-0">
                  <button
                    className="btn btn-success mr-2 mx-sm-2"
                    onClick={() => formik.handleSubmit()}
                  >
                    Save
                  </button>
                </div>
                <div className="mt-4 mt-sm-0">
                  <Link
                    className="btn btn-car-back"
                    to="/setting/edm-header-footer"
                  >
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AddAPIKey;
