import api from "../common/api";

export const registerUUID_v1 = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getwithToken(`company/list/all`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const registerUUID = (body: any, signal?: AbortSignal) => {
  return new Promise(async (resolve, reject) => {
    return api
      .registerUUID(`whatsapp/register-number`, body, signal)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        if (err.name === "AbortError") {
          console.log("Request was aborted");
        }
        reject(err);
      });
  });
};

export const clearWhatsappSession = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .clearSession(`whatsapp/clear-session`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const clearWhatsappRegisteringSession = (body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .clearSession(`whatsapp/clear-registering-session`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const updateCompany = (id: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putwithToken(`company/${id}`, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
