import React, { FC } from "react";
// import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

interface Select {
  value: any;
  label: string;
}

interface Props {
  value: any;
  setValue: (value: any) => void;
  options: Select[];
  className?: string;
  name: string;
  noLabel?: boolean;
}

export const Select: FC<Props> = (props) => {
  const { value, setValue, options, className, name, noLabel = false } = props;
  return (
    <div className="w-100">
      <div
        className={`d-flex align-items-center ${className}`}
        style={{ position: "relative", minWidth: "12rem" }}
      >
        <select
          className="form-control rounded custom-select"
          name={name}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          value={value}
          style={{ paddingRight: "3rem" }}
        >
          {options.map((option: Select, ind: number) => (
            <option key={ind} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {/* <ExpandMoreRoundedIcon
          style={{ position: "absolute", right: "1rem" }}
        /> */}
      </div>
      {!noLabel && (
        <small className="form-text text-muted">
          <b>Filter</b> by {name}
        </small>
      )}
    </div>
  );
};
