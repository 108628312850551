import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { v4 as uuidv4 } from "uuid";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import CreatableSelect from "react-select/creatable";
import { useFormik } from "formik";
import { InboxOutlined } from "@ant-design/icons";
import { UploadProps } from "antd";
import { Upload } from "antd";
import {
  getOneGatway,
  addNewGateway,
  updateGatway,
} from "../../../service/gatewayServices";
import { getAllCompanies } from "../../../service/companyService";
import {
  registerUUID,
  clearWhatsappRegisteringSession,
  clearWhatsappSession,
} from "../../../service/whatsappWebServices";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
import Loader from "../../../common/loader";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import "./addGateway.css"; // Import the CSS file

interface Values {
  gatewayName: string;
  companyId: any;
  type: string;
  connectionURL: string;
  loginName: string;
  password: string;
  port: string;
  otherKey: string;
  enabled: boolean;
  recieverInfoURL: string;
  authKey: string;
  authValue: string;
  template_name: string;
  lang_code: string;
  registeredUUIDs: { uuid: string; phone: string }[];
}

interface Params {
  id?: string;
}

const { Dragger } = Upload;

const AddGateway = () => {
  const history = useHistory();
  const [gateway, setGateway] = useState<any>({});
  const [uuidDialog, setUuidDialog] = useState<boolean>(false);
  const [qrCode, setQrCode] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const params: Params = useParams();
  const [selectedFile, setSelectedFile] = useState<any>([]);
  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(true);
  const [companyList, setCompanyList] = useState<any>([]);
  const [registeredUUIDs, setRegisteredUUIDs] = useState<any[]>([]);
  const [registering, setRegistering] = useState<boolean>(false);
  const [qrReceived, setQrReceived] = useState<boolean>(false);
  const [generatedUUIDSsessionIds, setGeneratedUUIDSsessionIds] = useState<
    any[]
  >([]);
  const [tempRegisteredIds, setTempRegisteredIds] = useState<any[]>([]);

  const [tempDeletedUUIDs, setTempDeletedUUIDs] = useState<any[]>([]);
  const [eventSourceCopy, setEventSourceCopy] = useState<any>(null);
  // const [isRegistering, setIsRegistering] = useState(false);
  const [
    abortController,
    setAbortController,
  ] = useState<AbortController | null>(null);

  const handleDelete = (index: number) => {
    const updatedUUIDs = registeredUUIDs.filter((_, i) => i !== index);
    setTempDeletedUUIDs((prev) => [...prev, registeredUUIDs[index]]);
    setRegisteredUUIDs(updatedUUIDs);
  };

  const handleSavePhone = (index: number, phone: string) => {
    const updatedUUIDs = [...registeredUUIDs];
    updatedUUIDs[index].phone = phone;
    updatedUUIDs[index].isEditing = false;
    setRegisteredUUIDs(updatedUUIDs);
  };

  const handleCancel = () => {
    if (abortController) {
      abortController.abort();
      setAbortController(null);
      setRegistering(false);
    }
    const test = [...generatedUUIDSsessionIds];

    // Filter out test UUIDs from registeredUUIDs
    const updatedRegisteredUUIDs = registeredUUIDs.filter(
      (item) => !test.includes(item.uuid)
    );

    setRegisteredUUIDs((prev) => [
      ...updatedRegisteredUUIDs,
      ...tempDeletedUUIDs,
    ]);
    setTempDeletedUUIDs([]);
    setUuidDialog(false);
    handleClearSession();
    setQrCode("");
    setUuidDialog(false);
    setQrReceived(false);
    setRegistering(false);
    eventSourceCopy?.close();
  };

  // NOTE: this is for clearing the whatsapp session for both registered or not registered temp
  // const handleClearRegisteringSession = () => {
  //   const uniqueUUIDs = Array.from(new Set([...generatedUUIDSsessionIds]));

  //   clearWhatsappRegisteringSession({ uuids: uniqueUUIDs }).then((res) => {
  //     setGeneratedUUIDSsessionIds([]);
  //   });
  // };

  // NOTE: this is cleared
  const handleClearSession = () => {
    const uniqueUUIDs = Array.from(new Set([...tempRegisteredIds]));

    clearWhatsappSession({ uuids: uniqueUUIDs }).then((res) => {
      setTempRegisteredIds([]);
    });
  };

  const handleRegister = () => {
    const controller = new AbortController();
    const { signal } = controller;
    setAbortController(controller);

    setRegistering(true);
    // setIsRegistering(true);
    const sessionId = uuidv4();

    setGeneratedUUIDSsessionIds((prev) => [...prev, sessionId]);

    registerUUID({ uuid: sessionId }, signal)
      .then((res: any) => {
        setRegistering(false);
        setAbortController(null);
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          console.log("Request was aborted");
        } else {
          console.error("Error registering UUID:", err);
          toast.error("Failed to register UUID");
        }
        setRegistering(false);
        setAbortController(null);
      });
  };

  const getAllCompaniesData = () => {
    getAllCompanies()
      .then((res: any) => {
        const newOptions = res?.data?.data?.map((x) => {
          return {
            label: x?.name,
            value: x?.name,
          };
        });
        setCompanyList(newOptions || []);
      })
      .catch((err) => {});
  };

  const getOneGatewayDetails = () => {
    setLoader(true);
    getOneGatway(params?.id)
      .then((res: any) => {
        setLoader(false);
        setGateway(res?.data);
        setIsSwitchOn(Boolean(res?.data?.status === "Active"));
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const props: UploadProps = {
    name: "file",
    action: "dummy",
    accept: "application/json",
    maxCount: 1,
    onRemove: (file) => {
      const index = selectedFile.indexOf(file);
      const newFileList = selectedFile.slice();
      newFileList.splice(index, 1);
      setSelectedFile(newFileList);
    },
    beforeUpload: (file, filess) => {
      return false;
    },
    onChange: (file) => {
      if (file.fileList) {
        setSelectedFile(file.fileList);
      }
    },
  };

  const onUpdate = (value: Values) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("gatewayName", value.gatewayName);
    formData.append("companyId", value.companyId?.value);
    formData.append("type", value.type);
    formData.append("connectionURL", value.connectionURL);
    formData.append("loginName", value.loginName);
    value.registeredUUIDs.forEach((uuid, index) => {
      formData.append(`registeredUUIDs[${index}][uuid]`, uuid.uuid);
      formData.append(`registeredUUIDs[${index}][phone]`, uuid.phone);
    });
    formData.append("password", value.password);
    formData.append("port", value.port);
    formData.append("otherKey", value.otherKey);
    formData.append("recieverInfoURL", value.recieverInfoURL);
    formData.append("authKey", value.authKey);
    formData.append("authValue", value.authValue);
    formData.append("status", isSwitchOn === true ? "Active" : "Inactive");
    if (selectedFile.length) {
      formData.append("file", selectedFile[0]?.originFileObj);
    }
    if (value.type === "WHATSAPP") {
      formData.append("template_name", value.template_name);
      formData.append("lang_code", value.lang_code);
    }
    updateGatway(params.id, formData)
      .then((res: any) => {
        setLoader(false);

        toast.success("Gateway update Successful");
        history.push("/gateway");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response.data.message);
      });
  };

  const onSubmit = (value: Values) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("gatewayName", value.gatewayName);
    formData.append("companyId", value.companyId?.value);
    formData.append("type", value.type);
    formData.append("connectionURL", value.connectionURL);
    formData.append("loginName", value.loginName);
    formData.append("password", value.password);
    formData.append("port", value.port);
    formData.append("otherKey", value.otherKey);
    formData.append("recieverInfoURL", value.recieverInfoURL || "");
    // formData.append("registeredUUIDs", value.registeredUUIDs);
    value.registeredUUIDs.forEach((uuid, index) => {
      formData.append(`registeredUUIDs[${index}][uuid]`, uuid.uuid);
      formData.append(`registeredUUIDs[${index}][phone]`, uuid.phone);
    });
    formData.append("authKey", value.authKey || "");
    formData.append("authValue", value.authValue || "");
    formData.append("status", isSwitchOn === true ? "Active" : "Inactive");
    if (selectedFile.length) {
      formData.append("file", selectedFile[0]?.originFileObj);
    }
    if (value.type === "WHATSAPP") {
      formData.append("template_name", value.template_name);
      formData.append("lang_code", value.lang_code);
    }
    addNewGateway(formData)
      .then((res: any) => {
        setLoader(false);
        toast.success("Gateway Add Successful");
        history.push("/gateway");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response.data.message);
      });
  };

  const companyValue = params.id
    ? { label: gateway?.companyId, value: gateway?.companyId }
    : "";

  const formik = useFormik({
    initialValues: {
      gatewayName: params.id ? gateway?.gatewayName : "",
      companyId: companyValue,
      type: params.id ? gateway?.type : "SMS",
      connectionURL: params.id ? gateway?.connectionURL : "",
      loginName: params.id ? gateway?.loginName : "",
      password: params.id ? gateway?.password : "",
      port: params.id ? gateway?.port : "",
      otherKey: params.id ? gateway?.otherKey : "",
      recieverInfoURL: params.id
        ? gateway?.recieverInfoURL
          ? gateway?.recieverInfoURL
          : ""
        : "",
      authKey: params.id ? (gateway?.authKey ? gateway?.authKey : "") : "",
      authValue: params.id
        ? gateway?.authValue
          ? gateway?.authValue
          : ""
        : "",
      enabled: params.id ? Boolean(gateway?.enabled === "Active") : true,
      template_name: params.id ? gateway?.template_name : "",
      lang_code: params.id ? gateway?.lang_code : "",
      registeredUUIDs: params.id ? gateway?.registeredUUIDs : [],
    },
    enableReinitialize: true,
    onSubmit: (values: Values) => {
      params.id ? onUpdate(values) : onSubmit(values);
    },
  });

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  useEffect(() => {
    if (params.id) {
      getOneGatewayDetails();
    }
    getAllCompaniesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    if (params.id) {
      setRegisteredUUIDs(gateway?.registeredUUIDs ?? []);
    }
  }, [gateway?.registeredUUIDs, params.id]);

  useEffect(() => {
    if (uuidDialog && formik.values.type === "WHATSAPP_WEB") {
      const eventSource = new EventSource(
        process.env.REACT_APP_API_URL + "whatsapp-events" ||
          "http://localhost:5001/api/v1/whatsapp-events"
      );

      setEventSourceCopy(eventSource);

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("Received event:", data);

        if (data.type === "qr_received") {
          console.log("triggered qr code", data);
          setQrReceived(true);
          setRegistering(true);
          setQrCode(data.data?.qr);
        }

        if (data.type === "qr_registered") {
          setQrReceived(false);
          setRegistering(false);
          setQrCode("");
          setRegisteredUUIDs((prev) => {
            // NOTE: the process is assuming if it exists
            // const exists = prev.some((item) => item.uuid === data.uuid);

            // if (exists) {
            //   return prev.map((item) =>
            //     item.uuid === data.uuid ? { ...item, ...data } : item
            //   );
            // } else {
            //   return [...prev, data];
            // }
            const d1 = [...prev, { uuid: data.data.uuid, phone: "" }];
            console.log(d1, "data here");
            return d1;
          });
          setTempRegisteredIds((prev) => [...prev, data.data.uuid]);
        }

        if (data.type === "completed") {
          eventSource.close();
          console.log("EventSource connection closed");
        }
      };

      eventSource.onerror = (error) => {
        console.error("EventSource failed:", error);
      };

      return () => {
        eventSource.close();
        console.log("EventSource connection closed on cleanup");
      };
    } else {
      return () => {};
    }
  }, [uuidDialog, formik.values.type]);

  return (
    <div>
      <Card>
        {loader && <Loader />}
        <CardHeader
          title={params?.id ? "Edit Gateway" : "New Gateway"}
        ></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Gateway Name</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="gatewayName"
                    value={formik.values.gatewayName}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Company</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <CreatableSelect
                    options={companyList}
                    isClearable={false}
                    className="react-select"
                    classNamePrefix="select"
                    onChange={(opt: any) => {
                      formik.setFieldValue("companyId", opt);
                    }}
                    value={formik.values.companyId}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Type</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <select
                    className="form-control"
                    id="title"
                    name="type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                  >
                    <option value="SMS">SMS</option>
                    <option value="WHATSAPP">Whatsapp</option>
                    <option value="WHATSAPP_WEB">Whatsapp Web</option>
                    <option value="EMAIL">Email</option>
                    <option value="PUSH NOTIFICATION">
                      Push Notification (IOS & Android)
                    </option>
                  </select>
                  <ExpandMoreRoundedIcon
                    style={{
                      position: "absolute",
                      right: "20px",
                      top: "10px",
                    }}
                  />
                </div>
              </div>
            </div>

            {formik.values.type === "SMS" ? (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">
                  Phone (twillio/firebase)
                </div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      className="form-control"
                      id="title"
                      name="otherKey"
                      value={formik.values.otherKey}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {formik.values.type === "EMAIL" ? (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap ">Connection URL</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="connectionURL"
                      value={formik.values.connectionURL}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {formik.values.type === "PUSH NOTIFICATION" ? (
              <div className="col-lg-10 col-md-10 col-sm-12 ">
                <div className="col-lg-4 ws-nowrap">Firebase JSON</div>
                <div className="col-lg-12 ws-nowrap">
                  <div className="form-group">
                    <Dragger {...props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </Dragger>
                  </div>
                </div>
              </div>
            ) : null}

            {formik.values.type === "WHATSAPP_WEB" ? (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">Register UUID</div>
                {formik.values.registeredUUIDs.map((uuidItem, index) => (
                  <div key={index} className="uuid-item">
                    <span>
                      <b>UUID: </b>
                    </span>
                    <span>{uuidItem.uuid}</span>
                    <span>
                      <b> Phone: </b>
                    </span>
                    <span>{uuidItem.phone}</span>
                  </div>
                ))}
                <div className="col-lg-8">
                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-lg w-100"
                      onClick={() => setUuidDialog(true)}
                    >
                      Register
                    </button>
                    {uuidDialog && (
                      <div
                        style={{
                          display: "flex",
                          position: "fixed",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          justifyContent: "center",
                          alignItems: "center",
                          zIndex: 1050,
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            borderRadius: "4px",
                            width: "80%",
                            maxWidth: "500px",
                            padding: "20px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <div className="modal-header">
                            <h5 className="modal-title">ASSIGN QR CODE</h5>
                            <button
                              type="button"
                              className="close"
                              onClick={handleCancel}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          {registeredUUIDs.map((uuidItem, index) => (
                            <div key={index} className="uuid-item">
                              <span>
                                <b>UUID: </b>
                              </span>
                              <span>{uuidItem.uuid}</span>
                              <span>
                                <b> Phone: </b>
                              </span>
                              {uuidItem.isEditing ? (
                                <>
                                  <input
                                    type="text"
                                    value={uuidItem.phone}
                                    onChange={(e) => {
                                      const updatedUUIDs = [...registeredUUIDs];
                                      updatedUUIDs[index].phone =
                                        e.target.value;
                                      setRegisteredUUIDs(updatedUUIDs);
                                    }}
                                  />
                                  <button
                                    className="btn btn-primary"
                                    onClick={() =>
                                      handleSavePhone(index, uuidItem.phone)
                                    }
                                  >
                                    Save
                                  </button>
                                </>
                              ) : (
                                <>
                                  <span>{uuidItem.phone}</span>
                                  <div>
                                    <button
                                      className="btn btn-secondary"
                                      onClick={() => {
                                        const updatedUUIDs = [
                                          ...registeredUUIDs,
                                        ];
                                        updatedUUIDs[index].isEditing = true;
                                        setRegisteredUUIDs(updatedUUIDs);
                                      }}
                                    >
                                      Edit
                                    </button>
                                    <button
                                      className="btn btn-danger"
                                      onClick={() => handleDelete(index)}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                          <div className="modal-body">
                            <p>Click the add button to fetch the QR code.</p>
                            <button
                              className="btn btn-primary btn-lg w-100"
                              onClick={handleRegister}
                              disabled={registering || qrReceived}
                            >
                              Add
                            </button>
                            {qrCode && (
                              <img
                                src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
                                  qrCode
                                )}&size=256x256`}
                                alt="QR Code"
                                style={{
                                  marginTop: "20px",
                                  width: "100%",
                                  height: "auto",
                                }}
                              />
                            )}
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => {
                                formik.setFieldValue(
                                  "registeredUUIDs",
                                  registeredUUIDs
                                );
                                if (abortController) {
                                  abortController.abort();
                                  setAbortController(null);
                                }
                                setUuidDialog(false);
                                setQrCode("");
                                setQrReceived(false);
                                setRegistering(false);
                              }}
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={handleCancel}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null}

            {formik.values.type === "PUSH NOTIFICATION" ||
            formik.values.type === "WHATSAPP_WEB" ? null : (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">
                  {formik.values.type === "SMS"
                    ? "SID"
                    : formik.values.type === "WHATSAPP" ||
                      formik.values.type === "WHATS"
                    ? "PhoneNumberID"
                    : "Login Name"}
                </div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="loginName"
                      value={formik.values.loginName}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            )}

            {formik.values.type === "PUSH NOTIFICATION" ||
            formik.values.type === "WHATSAPP_WEB" ? null : (
              <div
                className={
                  formik.values.type === "WHATSAPP"
                    ? "col-lg-12 col-md-12 col-sm-12"
                    : "col-lg-6 col-md-6 col-sm-12"
                }
              >
                <div className="col-lg-4 ws-nowrap">
                  {formik.values.type === "SMS"
                    ? "Auth Token"
                    : formik.values.type === "WHATSAPP"
                    ? "Access Token"
                    : "Password"}
                </div>
                <div
                  className={
                    formik.values.type === "WHATSAPP" ? "col-lg-10" : "col-lg-8"
                  }
                >
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            )}

            {formik.values.type === "WHATSAPP" ? (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">Template Name</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="template_name"
                      value={formik.values.template_name}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {formik.values.type === "WHATSAPP" ? (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">Language Code</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="lang_code"
                      value={formik.values.lang_code}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {formik.values.type === "EMAIL" ? (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">Port</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="port"
                      value={formik.values.port}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Reciever Info URL</div>
              <div className="col-lg-10">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="recieverInfoURL"
                    value={formik.values.recieverInfoURL}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Auth Header Key</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="authKey"
                    value={formik.values.authKey}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Auth Header Value</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <input
                    className="form-control"
                    id="title"
                    name="authValue"
                    value={formik.values.authValue}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "0.5px" }}>
            <div className="col-12 col-sm-3 mb-4 mb-sm-0 ws-nowrap d-flex justify-content-between align-items-center">
              <span>Status</span>
              <FormControlLabel
                control={
                  <Switch
                    checked={isSwitchOn}
                    onChange={onSwitchAction}
                    color="primary"
                    name="enabled"
                  />
                }
                label={isSwitchOn ? "Active" : "Inactive"}
                className="mb-0"
              />
            </div>
          </div>
        </CardBody>

        <CardFooter>
          <div className="row">
            <div className="col-lg-10">
              <div className="d-flex flex-wrap align-items-center justify-content-end">
                <div className="mt-4 mt-sm-0">
                  <button
                    className="btn btn-success mr-2 mx-sm-2"
                    onClick={() => formik.handleSubmit()}
                  >
                    Save
                  </button>
                </div>
                <div className="mt-4 mt-sm-0">
                  <Link className="btn btn-car-back" to="/gateway">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AddGateway;
