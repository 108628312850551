import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DevURL } from "../../../common/constant/defalut";
import { LOCAL_STORAGE_KEYS } from "../../../common/constant/defalut";
import MultipleDatesPicker from "@randex/material-ui-multiple-dates-picker";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import { useFormik } from "formik";
import {
  getOneMessage,
  addNewMessage,
  updateMessage,
  updateGroupMessage,
  getGroupFirstMessage,
} from "../../../service/messageService";
import { getAllEDMHeaderFooter } from "../../../service/settings/edmHeaderFooter";
import mongoose from "mongoose";
import { getGateways } from "../../../service/gatewayServices";
import { toast } from "react-toastify";
import SearchSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import { useHistory, useParams } from "react-router";
import Loader from "../../../common/loader";
import _ from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { Modal } from "react-bootstrap";
import SNChart from "../../../common/components/SNChartChart";
import ViewClickModal from "./viewClickModal";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";
import "froala-editor/js/plugins.pkgd.min.js";

function sum(arr) {
  let sum = 0;
  for (let i = 0; i < arr.length; i++) sum += arr[i];

  return sum;
}

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const monthOptions = [
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "Mach", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "Augest", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

const dayOptions = [
  { label: "Sunday", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
];

const repeatTypeVal = {
  YEAR: "years",
  MONTH: "months",
  WEEK: "weeks",
  DAY: "days",
};

const enumerateDaysBetweenDates = function (
  startDate = moment().format("YYYY-MM-DD"),
  endDate = moment()
    .add(7, "days")
    .format("YYYY-MM-DD"),
  kind = "DAY"
) {
  const kindOf = repeatTypeVal[kind];
  const dates: any = [];
  const StartDate = moment(startDate).clone();

  while (StartDate.isSameOrBefore(endDate)) {
    dates.push({
      date: StartDate.format("YYYY-MM-DD"),
      day: StartDate.format("dddd"),
    });
    StartDate.add(1, kindOf);
  }
  return dates;
};

const getMinutes = (time = "") => {
  const timeUTCval = moment(`2022-01-01T${time}:00Z`).format("HH:mm");
  return timeUTCval;
};

const getPassMinutes = (time = "") => {
  const timeSplit = time.split(":");
  const timeUTCval: any = moment("2022-01-01")
    .add({ hours: Number(timeSplit[0]), minutes: Number(timeSplit[1]) })
    .utc()
    .format("HH:mm");
  return timeUTCval;
};

interface Values {
  title: string;
  gatewayId: string;
  repeat: string;
  messageType: string;
  recieverInfo: any;
  content: string;
  startTime: string;
  endTime: string;
  scheduleTime: string;
  selectedDays: any;
  header_footer: string;
}

const AddMessage = () => {
  const isEDM = window.location.pathname.includes("edm");
  const history = useHistory();
  const [gatewayList, setGatewayList] = useState<any>([]);
  const [receiverList, setReceiverList] = useState<any>([]);
  const [message, setMessage] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [forFuture, setforFuture] = useState(false);
  const [sendNow, setSendNow] = useState<string>("false");
  const [sendTimeValue, setSendTimeValue] = useState("");
  const [eachOnto, setEachOnTo] = useState<string>("EACH");
  const [selectedType, setSelectedType] = useState<string>("FIRST");
  const [selectedDay, setSelectedDay] = useState<string>("DAY");
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [selectedDates, setSelectedDates] = useState<any>([]);
  const [selectedMonths, setSelectedMonths] = useState<any>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<any>([]);
  const [headerFooterList, setHeaderFooterList] = useState([]);
  const [showClickModel, setShowClickModel] = useState<boolean>(false);
  const [showViewModel, setShowViewModel] = useState<boolean>(false);
  const params: any = useParams();
  const isCloneMode: any = Boolean(params.grpId);

  // EDMAIL CONTENT EDITOR CONFIG
  const config = {
    imageUploadParam: "file",
    htmlExecuteScripts: true,
    imageUploadMethod: "POST",
    imageUploadURL: `${DevURL}media/upload`,
    requestHeaders: {
      Authorization:
        localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "",
    },
    placeholderText: "Edit Your Content Here!",
    pluginsEnabled: [
      "align",
      "charCounter",
      "codeBeautifier",
      "codeView",
      "colors",
      "draggable",
      "emoticons",
      "entities",
      "fontFamily",
      "fontSize",
      "fullscreen",
      "help",
      "image",
      "inlineClass",
      "inlineStyle",
      "link",
      "lists",
      "paragraphFormat",
      "paragraphStyle",
      "print",
      "quote",
      "save",
      "specialCharacters",
      "table",
      "url",
      "video",
      "wordPaste",
    ],

    toolbarButtons: {
      moreText: {
        buttons: [
          "bold",
          "italic",
          "underline",
          "strikeThrough",
          "subscript",
          "superscript",
          "fontFamily",
          "fontSize",
          "textColor",
          "backgroundColor",
          "inlineClass",
          "inlineStyle",
          "clearFormatting",
        ],
        buttonsVisible: 3,
      },
      moreParagraph: {
        buttons: [
          "alignLeft",
          "alignCenter",
          "alignRight",
          "alignJustify",
          "outdent",
          "indent",
          "formatOL",
          "formatUL",
          "paragraphFormat",
          "paragraphStyle",
          "quote",
        ],
        buttonsVisible: 6,
      },
      moreRich: {
        buttons: [
          "insertLink",
          "insertImage",
          "insertVideo",
          "insertTable",
          "emoticons",
          "specialCharacters",
          "insertHR",
        ],
        buttonsVisible: 4,
      },
      moreMisc: {
        buttons: [
          "undo",
          "redo",
          "fullscreen",
          "print",
          "getPDF",
          "selectAll",
          "html",
          "help",
          "save",
        ],
        align: "right",
        buttonsVisible: 2,
      },
    },
  };

  const uploadImage = (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization:
          localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "",
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 }, file);
      },
    };
    fmData.append("file", file);
    axios
      .post(`${DevURL}media/upload`, fmData, config)
      .then((res: any) => {
        onSuccess(res.data.link);
      })
      .catch((err) => {
        const error = new Error("Some error");
        onError({ event: error });
      });
  };

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.name || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name);
  };

  let titleToshow: any = String(previewTitle).split("/")
  titleToshow = titleToshow[titleToshow.length - 1 || 0]

  const handleChange = ({ fileList: newFileList }) => {
    const newList = newFileList.map((x) => {
      x.altText = x.altText || "";
      x.externalUrl = x.externalUrl || "";
      if (!x.url) {
        x.name = x.response;
        x.url = x.response;
      }
      if (!x._id) {
        x._id = JSON.parse(JSON.stringify(new mongoose.Types.ObjectId()));
      }
      return x;
    });
    setFileList(newList);
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const fetchGatewayList = () => {
    const isStatus = isEDM
      ? `&filter={"status":"Active","type":"EMAIL"}`
      : `&filter={"status":"Active"}`;
    const isSortColumn = `&sort={"name": 1}`;
    getGateways(1, 11111, "", isStatus, isSortColumn).then((res: any) => {
      const listData = res.data?.docs.map((data: any) => {
        return {
          label: `${data?.gatewayName} (${data?.type})`,
          value: data?._id,
          msgKind: data.type,
          recieverInfoURL: data?.recieverInfoURL,
          authKey: data?.authKey,
          authValue: data?.authValue,
        };
      });
      setGatewayList(listData);
    });
  };

  const fetchHeaderFooterList = () => {
    getAllEDMHeaderFooter().then((res: any) => {
      const listData = res.data?.data.map((data: any) => {
        return {
          label: data?.name,
          value: data?._id,
        };
      });
      setHeaderFooterList(listData);
    });
  };

  const getOneMessageDetails = () => {
    setLoader(true);
    getOneMessage(params?.id)
      .then((res: any) => {
        setLoader(false);
        setMessage(res?.data);
        setSelectedDates(res.data.selectedDates);
        setSelectedMonths(res.data.selectedMonths);
        if (res.data.media && res.data.media.length) {
          const mediaToShow = res.data.media.map((x: any, index: any) => {
            const dataToShow = {};
            dataToShow["uid"] = String(`-${index + 1}`);
            dataToShow["name"] = x.name || "";
            dataToShow["status"] = "done";
            dataToShow["url"] = x.name || "";
            dataToShow["altText"] = x.altText || "";
            dataToShow["externalUrl"] = x.externalUrl || "";
            if (x._id) {
              dataToShow["_id"] = x._id || "";
            }
            if (x.openedBy) {
              dataToShow["openedBy"] = x.openedBy || [];
            }
            if (x.viewBy) {
              dataToShow["viewBy"] = x.viewBy || [];
            }
            return dataToShow;
          });
          setFileList(mediaToShow);
        }
        if (res.data.selectedDayOn.length === 2) {
          const dayPos = res.data.selectedDayOn;
          setSelectedType(dayPos[0]);
          setSelectedDay(dayPos[1]);
          setEachOnTo("ONTO");
        } else {
          setEachOnTo("EACH");
        }
        setSendTimeValue(getMinutes(res.data.sendTime));
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response?.data?.message || "");
      });
  };

  const fetchRecieversList = (gateway: any) => {
    const { authKey, authValue, recieverInfoURL, msgKind } = gateway;
    const authHeader = {};
    if (authKey !== "") {
      authHeader[authKey] = authValue;
    }
    if (recieverInfoURL) {
      axios
        .get(recieverInfoURL, { headers: authHeader })
        .then((res: any) => {
          if (res.data.length) {
            const dataTosave: any = [];
            for (const item of res.data) {
              if (item.groupName) {
                if (!Boolean(params.id)) {
                  dataTosave.push({
                    label: `(G) ${item.groupName}`,
                    value: item.data.map((x: any) =>
                      msgKind === "SMS"
                        ? x["mobile"]
                        : msgKind === "WHATSAPP"
                          ? x["mobile"]
                          : msgKind === "EMAIL"
                            ? x["email"]
                            : x["deviceToken"]
                    ),
                  });
                }
                item.data.map((x: any) => {
                  dataTosave.push({
                    label: x.username,
                    value:
                      msgKind === "SMS"
                        ? x["mobile"]
                        : msgKind === "WHATSAPP"
                          ? x["mobile"]
                          : msgKind === "EMAIL"
                            ? x["email"]
                            : x["deviceToken"],
                  });
                  return x;
                });
              } else {
                dataTosave.push({
                  label: item.username,
                  value:
                    msgKind === "SMS"
                      ? item["mobile"]
                      : msgKind === "WHATSAPP"
                        ? item["mobile"]
                        : msgKind === "EMAIL"
                          ? item["email"]
                          : item["deviceToken"],
                });
              }
            }
            setReceiverList(dataTosave);
          }
        })
        .catch((err) => { });
    }
  };

  const dayDate = params.id
    ? moment(message?.scheduleTime).format("YYYY-MM-DD")
    : moment().format("YYYY-MM-DD");
  const getDaysStart = moment(dayDate).format("YYYY-MM-DD");
  const getDaysEnd = moment(dayDate)
    .add(7, "days")
    .format("YYYY-MM-DD");
  const nextDays = enumerateDaysBetweenDates(getDaysStart, getDaysEnd);

  const onUpdate = (value: any) => {
    let selectedDayOn: any = [];
    let selectedDatesToPass: any = [];
    if (
      eachOnto === "ONTO" &&
      selectedDay &&
      selectedType &&
      selectedDay !== "" &&
      selectedType !== ""
    ) {
      selectedDayOn = [selectedType, selectedDay];
      selectedDatesToPass = [];
    } else {
      selectedDatesToPass = selectedDates;
      selectedDayOn = [];
    }

    if (
      value.repeat === "YEAR" &&
      selectedDay &&
      selectedType &&
      selectedDay !== "" &&
      selectedType !== ""
    ) {
      selectedDayOn = [selectedType, selectedDay];
    }
    const recieversTosave = () => {
      const dataToPass: any = [];
      for (const item of value.recieverInfo) {
        if (typeof item === "string") {
          dataToPass.push(item);
        } else if (typeof item === "object") {
          item.map((x: any) => {
            dataToPass.push(x);
            return x;
          });
        }
      }
      return _.uniq(dataToPass);
    };
    const schedDateTime = String(value.scheduleTime).split("T");
    const passTime = schedDateTime[1] ? getPassMinutes(schedDateTime[1]) : "";
    setLoader(true);
    const body = {
      gatewayId: value.gatewayId,
      title: value.title,
      repeatType: value.repeat,
      selectedDays: value.selectedDays,
      messageType: value.messageType,
      recieverInfo: recieversTosave(),
      content: value.content,
      scheduleTime:
        value.repeat === "WEEK"
          ? nextDays.find((x: any) => x.day === value.startTime)?.date
          : schedDateTime[0],
      selectedDayOn: selectedDayOn,
      selectedDates: selectedDatesToPass,
      selectedMonths: selectedMonths,
      sendTime:
        value.repeat === "WEEK"
          ? getPassMinutes(sendTimeValue)
          : value.repeat === "MONTH"
            ? getPassMinutes(sendTimeValue)
            : value.repeat === "DAY"
              ? getPassMinutes(value?.startTime)
              : value.repeat === "YEAR"
                ? getPassMinutes(sendTimeValue)
                : passTime,
    };
    if (fileList.length) {
      body["header_footer"] =
        value?.header_footer && value?.header_footer !== ""
          ? value?.header_footer
          : null;
      body["media"] = fileList
        .filter((x: any) => x.status === "done")
        .map((item) => {
          const payload = {};
          payload["name"] = item.name || "";
          payload["altText"] = item.altText || "";
          payload["externalUrl"] = item.externalUrl || "";
          if (item._id) {
            payload["_id"] = item._id || "";
          }
          if (item.openedBy) {
            payload["openedBy"] = item.openedBy;
          }
          if (item.viewBy) {
            payload["viewBy"] = item.viewBy;
          }
          return payload;
        });
    }

    if (forFuture) {
      updateGroupMessage(message?.groupId, params.id, body)
        .then((res: any) => {
          setLoader(false);

          toast.success("Reminder update Successful");
          setTimeout(() => {
            history.push("/message");
          }, 1500);
        })
        .catch((err) => {
          setLoader(false);
          toast.error(err.response.data.message);
        });
    } else {
      updateMessage(params.id, body)
        .then((res: any) => {
          setLoader(false);

          toast.success("Reminder update Successful");
          setTimeout(() => {
            history.push("/message");
          }, 1500);
        })
        .catch((err) => {
          setLoader(false);
          toast.error(err.response.data.message);
        });
    }
  };

  const onSubmit = (value: Values) => {
    let selectedDayOn: any = [];
    let selectedDatesToPass: any = [];
    if (
      eachOnto === "ONTO" &&
      selectedDay &&
      selectedType &&
      selectedDay !== "" &&
      selectedType !== ""
    ) {
      selectedDayOn = [selectedType, selectedDay];
      selectedDatesToPass = [];
    } else {
      selectedDatesToPass = selectedDates;
      selectedDayOn = [];
    }

    if (
      value.repeat === "YEAR" &&
      selectedDay &&
      selectedType &&
      selectedDay !== "" &&
      selectedType !== ""
    ) {
      selectedDayOn = [selectedType, selectedDay];
    }

    const recieversTosave = () => {
      const dataToPass: any = [];
      for (const item of value.recieverInfo) {
        if (typeof item === "string") {
          dataToPass.push(item);
        } else if (typeof item === "object") {
          item.map((x: any) => {
            dataToPass.push(x);
            return x;
          });
        }
      }
      return _.uniq(dataToPass);
    };
    const startDateTime = String(value.startTime).split("T");
    const endDateTime = String(value.endTime).split("T");
    const passTime = startDateTime[1] ? getPassMinutes(startDateTime[1]) : "";
    setLoader(true);
    const body = {
      gatewayId: value.gatewayId,
      title: value.title,
      selectedDays: value.selectedDays,
      repeatType: value.repeat,
      messageType: value.messageType,
      recieverInfo: recieversTosave(),
      selectedDayOn: selectedDayOn,
      selectedDates: selectedDatesToPass,
      selectedMonths: selectedMonths,
      content: value.content,
      startTime:
        value.repeat === "WEEK"
          ? nextDays.find((x: any) => x.day === value.startTime)?.date
          : value.repeat === "DAY"
            ? moment().format("YYYY-MM-DD")
            : startDateTime[0],
      endTime: endDateTime[0],
      sendTime:
        value.repeat === "WEEK"
          ? getPassMinutes(sendTimeValue)
          : value.repeat === "MONTH"
            ? getPassMinutes(sendTimeValue)
            : value.repeat === "DAY"
              ? getPassMinutes(value?.startTime)
              : value.repeat === "YEAR"
                ? getPassMinutes(sendTimeValue)
                : passTime,
    };

    if (isEDM) {
      body["header_footer"] = value?.header_footer || null;
      body["media"] = fileList
        .filter((x: any) => x.status === "done")
        .map((item) => {
          const payload = {};
          payload["name"] = item.name || "";
          payload["altText"] = item.altText || "";
          payload["externalUrl"] = item.externalUrl || "";
          if (item._id) {
            payload["_id"] = item._id || "";
          }
          return payload;
        });
    }

    addNewMessage(body)
      .then((res: any) => {
        setLoader(false);
        toast.success("Reminder Add Successful");
        setTimeout(() => {
          history.push("/message");
        }, 1500);
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.response.data.message);
      });
  };
  console.log("jigar fileList", fileList)
  const showScheduleTime = params.id
    ? `${moment(message?.scheduleTime).format("YYYY-MM-DD")}T${getMinutes(
      message?.sendTime
    )}`
    : "";

  const formik: any = useFormik({
    initialValues: {
      title: params.id && message?.title ? message?.title : "",
      gatewayId: params.id && message ? message?.gatewayId?._id : "",
      repeat: params.id && message ? message?.repeatType : "MONTH",
      messageType: params.id && message ? message?.messageType : "",
      recieverInfo: params.id && message ? message?.recieverInfo : [],
      scheduleTime: showScheduleTime ? showScheduleTime : "",
      content: params.id && message ? message?.content : "",
      header_footer:
        params?.id && message?.header_footer ? message?.header_footer : "",
      startTime:
        params.id && message
          ? message?.repeatType === "DAY"
            ? sendTimeValue
            : moment(message?.startTime).format("YYYY-MM-DD")
          : "",
      endTime:
        params.id && message
          ? moment(message.endTime).format("YYYY-MM-DD")
          : "",
      selectedDays:
        params.id && message?.selectedDays ? message?.selectedDays : [],
    },
    enableReinitialize: true,
    onSubmit: (values: Values) => {
      if (params.id) {
        onUpdate(values);
      } else {
        onSubmit(values);
      }
    },
  });

  const recieverListValues: any = receiverList.filter((x) => {
    return formik.values.recieverInfo.indexOf(x?.value) !== -1;
  });

  const recieversForValue =
    Boolean(formik.values.recieverInfo !== "") && receiverList.length
      ? [
        ...recieverListValues,
        ...formik.values.recieverInfo
          .filter((x) => {
            return recieverListValues.map((z) => z?.value).indexOf(x) === -1;
          })
          .map((x) => {
            return {
              label: x,
              value: x,
            };
          }),
      ]
      : Boolean(formik.values.recieverInfo !== "")
        ? formik.values.recieverInfo.map((x) => {
          return {
            label: x,
            value: x,
          };
        })
        : [];

  const selectedGatewayType: any = gatewayList.find(
    (x: any) => x.value === formik.values.gatewayId
  );

  useEffect(() => {
    setReceiverList([]);
    if (selectedGatewayType) {
      fetchRecieversList(selectedGatewayType);
    }
    formik.setFieldValue(
      "messageType",
      selectedGatewayType?.msgKind ? selectedGatewayType?.msgKind : ""
    );
    // eslint-disable-next-line
  }, [selectedGatewayType]);

  const isGrpMsg: boolean = Boolean(message?.groupId);

  const dayToshow: any = nextDays?.find(
    (x: any) => x.date === moment(message?.scheduleTime).format("YYYY-MM-DD")
  )?.day;

  // GET WANTED CLONE REMINDER DATA
  const getClonedGroupData = () => {
    getGroupFirstMessage(params.grpId)
      .then((res: any) => {
        const cloneData: any = res.data;
        if (cloneData?.content) {
          formik.setFieldValue("content", cloneData?.content);
        }
        if (cloneData?.recieverInfo) {
          formik.setFieldValue("recieverInfo", cloneData?.recieverInfo);
        }
        if (cloneData?.title) {
          formik.setFieldValue("title", cloneData?.title);
        }
        if (cloneData?.gatewayId?._id) {
          formik.setFieldValue("gatewayId", cloneData?.gatewayId?._id);
        }
        if (cloneData?.repeatType) {
          formik.setFieldValue("repeat", cloneData?.repeatType);
        }
        if (cloneData?.selectedDates) {
          setSelectedDates(cloneData.selectedDates);
        }
        if (cloneData?.selectedMonths) {
          setSelectedMonths(cloneData.selectedMonths);
        }
        if (cloneData?.selectedDays) {
          formik.setFieldValue("selectedDays", cloneData?.selectedDays);
        }
        if (cloneData?.sendTime) {
          formik.setFieldValue(
            "startTime",
            cloneData?.repeatType === "DAY"
              ? getMinutes(cloneData.sendTime)
              : moment(cloneData?.startTime).format("YYYY-MM-DD")
          );
          setSendTimeValue(getMinutes(cloneData.sendTime));
        }
        // TO SET EACH-ONTO
        if (res.data.selectedDayOn.length === 2) {
          const dayPos = res.data.selectedDayOn;
          setSelectedType(dayPos[0]);
          setSelectedDay(dayPos[1]);
          setEachOnTo("ONTO");
        } else {
          setEachOnTo("EACH");
        }
      })
      .catch((err: any) => { });
  };

  useEffect(() => {
    if (params.id && dayToshow) {
      formik.setFieldValue("startTime", dayToshow);
    }
    // eslint-disable-next-line
  }, [dayToshow]);

  useEffect(() => {
    if (sendNow === "true") {
      formik.setFieldValue("repeat", "NEVER");
    } else {
      formik.setFieldValue("repeat", "MONTH");
    }
    // eslint-disable-next-line
  }, [sendNow]);

  useEffect(() => {
    fetchGatewayList();
    if (params.id) {
      getOneMessageDetails();
    } else if (params.grpId) {
      getClonedGroupData();
    }
    if (isEDM || params.id) {
      fetchHeaderFooterList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, params.id, params.grpId]);

  const clickCounts: any =
    fileList.find((x: any) => x.name === previewTitle)?.openedBy?.length || 0;
  const viewCounts: any =
    fileList.find((x: any) => x.name === previewTitle)?.viewBy?.length || 0;

  const clickedByData = (
    fileList.find((x: any) => x.name === previewTitle)?.openedBy || []
  ).map((x: any, index: any) => {
    return {
      index: index + 1,
      user: x,
    };
  });

  const viewByData = (
    fileList.find((x: any) => x.name === previewTitle)?.viewBy || []
  ).map((x: any, index: any) => {
    return {
      index: index + 1,
      user: x,
    };
  });

  return (
    <div>
      <Card>
        {loader && <Loader />}
        <CardHeader
          title={
            isCloneMode
              ? "Clone Reminder"
              : params?.id
                ? "Edit Reminder"
                : isEDM
                  ? "EDM"
                  : "New Reminder"
          }
        ></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Gateway</div>
              <div className="col-lg-8">
                <div className="form-group">
                  <SearchSelect
                    options={gatewayList}
                    value={
                      gatewayList.find(
                        (x: any) => x?.value === formik.values.gatewayId
                      ) || ""
                    }
                    onChange={(opt) => {
                      formik.setFieldValue("recieverInfo", []);
                      if (opt) {
                        formik.setFieldValue("gatewayId", opt?.value);
                      } else {
                        formik.setFieldValue("gatewayId", "");
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="col-lg-4 ws-nowrap">Reciever</div>

              <div className="col-lg-8">
                <div className="form-group">
                  <CreatableSelect
                    isMulti
                    options={receiverList}
                    isClearable={false}
                    className="react-select"
                    classNamePrefix="select"
                    onChange={(opt: any) => {
                      let newReceivers: any = [];
                      if (opt) {
                        opt.map((x: any) => {
                          if (x.value.includes(";")) {
                            newReceivers = [
                              ...newReceivers,
                              ...x.value.split(";").filter((cc) => cc !== ""),
                            ];
                          }
                          if (x.value.includes(",")) {
                            newReceivers = [
                              ...newReceivers,
                              ...x.value.split(",").filter((cc) => cc !== ""),
                            ];
                          }
                          newReceivers = [...newReceivers, x.value];
                          return x;
                        });
                      }
                      formik.setFieldValue("recieverInfo", newReceivers);
                    }}
                    value={recieversForValue}
                  />
                </div>
              </div>
            </div>
          </div>

          {selectedGatewayType &&
            (selectedGatewayType?.msgKind === "EMAIL" ||
              selectedGatewayType?.msgKind === "PUSH NOTIFICATION") && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="col-lg-4 ws-nowrap">Title</div>
                  <div className="col-lg-10">
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

          <div className="row">
            <div className="col-lg-12">
              <div className="col-lg-4 ws-nowrap">Reminder Content</div>
              <div className="col-lg-10">
                <div className="form-group">
                  {selectedGatewayType?.msgKind === "EMAIL" ? (
                    <FroalaEditor
                      model={formik.values.content}
                      config={config}
                      onModelChange={(eContent) => {
                        formik.setFieldValue("content", eContent);
                      }}
                    />
                  ) : (
                    <textarea
                      rows={5}
                      className="form-control"
                      id="title"
                      name="content"
                      value={formik.values.content}
                      onChange={formik.handleChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {fileList.length ||
            (formik.values.header_footer && formik.values.header_footer !== "") ||
            isEDM ? (
            <div className="row mb-2">
              <div className="col-lg-12">
                <div className="col-lg-12 ws-nowrap">
                  <Upload
                    accept="image/*"
                    customRequest={uploadImage}
                    // action={`${DevURL}media/upload`}
                    // headers={{
                    //   Authorization:
                    //     localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) ||
                    //     "",
                    // }}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                  >
                    {fileList.length >= 8 ? null : uploadButton}
                  </Upload>
                </div>

                {/* IMAGE PREVIEW AND ALT-URL */}
                <Modal show={previewOpen}>
                  <Modal.Header>{titleToshow}</Modal.Header>
                  <Modal.Body>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 d-flex">
                        <img
                          alt={titleToshow}
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </div>
                      <div
                        className="col-lg-4 ws-nowrap"
                        style={{ marginTop: "20px" }}
                      >
                        ALT Text
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 d-flex">
                        <div className="form-group d-flex align-center w-100">
                          <input
                            type="text"
                            value={
                              fileList.find((x: any) => x.name === previewTitle)
                                ?.altText
                            }
                            className="form-control"
                            onChange={(e) => {
                              const newFileList = fileList.map((x: any) => {
                                if (x.name === previewTitle) {
                                  return {
                                    ...x,
                                    altText: e.target.value,
                                  };
                                }
                                return x;
                              });
                              setFileList(newFileList);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-lg-4 ws-nowrap">URL</div>
                      <div className="col-lg-12 col-md-12 col-sm-12 d-flex">
                        <div className="form-group d-flex align-center w-100">
                          <input
                            type="text"
                            className="form-control"
                            value={
                              fileList.find((x: any) => x.name === previewTitle)
                                ?.externalUrl
                            }
                            onChange={(e) => {
                              const newFileList = fileList.map((x: any) => {
                                if (x.name === previewTitle) {
                                  return {
                                    ...x,
                                    externalUrl: e.target.value,
                                  };
                                }
                                return x;
                              });
                              setFileList(newFileList);
                            }}
                          />
                        </div>
                      </div>

                      {isEDM ? null : (
                        <>
                          <div className="col-lg-12 ws-nowrap">
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                setShowClickModel(true);
                              }}
                            >
                              Clicks ({clickCounts || 0})
                            </button>
                            <button
                              className="btn btn-primary ml-1"
                              onClick={() => {
                                setShowViewModel(true);
                              }}
                            >
                              Views ({viewCounts || 0})
                            </button>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center">
                            <SNChart
                              type="radialBar"
                              options={{
                                chart: {
                                  height: 180,
                                  type: "radialBar",
                                },
                                plotOptions: {
                                  radialBar: {
                                    hollow: {
                                      size: "70%", //`${((clickCounts / formik.values.recieverInfo.length) * 100)}%`,
                                    },
                                  },
                                },
                                labels: ["Image Clicks"],
                              }}
                              series={[
                                (clickCounts /
                                  formik.values.recieverInfo.length) *
                                100,
                              ]}
                              height={180}
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center">
                            <SNChart
                              type="radialBar"
                              options={{
                                chart: {
                                  height: 180,
                                  type: "radialBar",
                                },
                                plotOptions: {
                                  radialBar: {
                                    hollow: {
                                      size: "70%", //`${((clickCounts / formik.values.recieverInfo.length) * 100)}%`,
                                    },
                                  },
                                },
                                labels: ["Image Views"],
                              }}
                              series={[
                                (viewCounts /
                                  formik.values.recieverInfo.length) *
                                100,
                              ]}
                              height={180}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      onClick={handleCancel}
                      className="btn btn-primary btn-elevate"
                    >
                      Close
                    </button>
                  </Modal.Footer>
                </Modal>

                {/* CLICKED BY MODEL */}
                <Modal size="lg" show={showClickModel}>
                  <Modal.Header>Image Clicked By</Modal.Header>
                  <Modal.Body>
                    <ViewClickModal data={clickedByData || []} />
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      onClick={() => {
                        setShowClickModel(false);
                      }}
                      className="btn btn-primary btn-elevate"
                    >
                      Close
                    </button>
                  </Modal.Footer>
                </Modal>

                {/* VIEWED BY MODEL */}
                <Modal size="lg" show={showViewModel}>
                  <Modal.Header>Image Viewed By</Modal.Header>
                  <Modal.Body>
                    <ViewClickModal data={viewByData || []} />
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      onClick={() => {
                        setShowViewModel(false);
                      }}
                      className="btn btn-primary btn-elevate"
                    >
                      Close
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          ) : null}

          {fileList.length ||
            (formik.values.header_footer && formik.values.header_footer !== "") ||
            isEDM ? (
            <div className="row mb-2">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">Header & Footer</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <SearchSelect
                      options={headerFooterList}
                      onChange={(optVal: any) => {
                        if (optVal) {
                          formik.setFieldValue("header_footer", optVal.value);
                        } else {
                          formik.setFieldValue("header_footer", "");
                        }
                      }}
                      value={headerFooterList.find(
                        (x: any) => x.value === formik.values.header_footer
                      )}
                    />
                  </div>
                </div>
                {/* <SearchSelect
                  className="w-100"
                  options={headerFooterList}
                  onChange={(optVal: any) => {
                    formik.setFieldValue("header_footer", optVal.value);
                  }}
                  value={headerFooterList.find(
                    (x: any) => x.value === formik.values.header_footer
                  )}
                /> */}
              </div>
              <div className="col-lg-2">
                Total Views:{" "}
                {sum(fileList.map((x: any) => x?.viewBy?.length || 0))}
              </div>
              <div className="col-lg-2">
                Total Clicks:{" "}
                {sum(fileList.map((x: any) => x?.openedBy?.length || 0))}
              </div>
            </div>
          ) : null}

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex">
              <div
                className="form-group d-flex align-center"
                style={{ marginTop: "20px", paddingLeft: "25px" }}
              >
                <input
                  type="radio"
                  name="sendNow"
                  value="true"
                  onClick={() => {
                    setSendNow("true");
                  }}
                  disabled={Boolean(params.id)}
                  checked={sendNow === "true"}
                />
                <div className="col-lg-4 ws-nowrap ">Send Now</div>
              </div>

              <div
                className="form-group d-flex align-center"
                style={{ marginTop: "20px", paddingLeft: "25px" }}
              >
                <input
                  type="radio"
                  name="sendNow"
                  disabled={Boolean(params.id)}
                  value="false"
                  onClick={() => {
                    setSendNow("false");
                  }}
                  checked={sendNow === "false"}
                />
                <div className="col-lg-4 ws-nowrap ">Send by schedule</div>
              </div>
            </div>
            {sendNow === "true" ? null : formik.values.repeat === "DAY" ||
              formik.values.repeat === "WEEK" ||
              formik.values.repeat === "MONTH" ||
              formik.values.repeat === "YEAR" ? (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">Repeat</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <select
                      className="form-control"
                      id="title"
                      disabled={Boolean(params.id)}
                      name="repeat"
                      value={formik.values.repeat}
                      onChange={formik.handleChange}
                    >
                      <option value="NEVER">Never</option>
                      <option value="DAY">Every Day</option>
                      <option value="WEEK">Every Week</option>
                      <option value="MONTH">Every Month</option>
                      <option value="YEAR">Every Year</option>
                    </select>
                    <ExpandMoreRoundedIcon
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "10px",
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : sendNow === "true" ? null : params.id ? (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap ">Schedule Time</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="datetime-local"
                      className="form-control"
                      id="title"
                      name="scheduleTime"
                      value={formik.values.scheduleTime}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">Schedule Time</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="datetime-local"
                      disabled={Boolean(params.id)}
                      className="form-control"
                      id="title"
                      name="startTime"
                      value={formik.values.startTime}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          {formik.values.repeat === "MONTH" ? (
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">Repeat on</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <select
                      className="form-control"
                      name="repeatOn"
                      value={eachOnto}
                      onChange={(e) => {
                        setEachOnTo(e.target.value);
                        setSelectedDates([]);
                        setSelectedDay("DAY");
                        setSelectedType("FIRST");
                      }}
                    >
                      <option value="EACH">Each</option>
                      <option value="ONTO">On To</option>
                    </select>
                    <ExpandMoreRoundedIcon
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "10px",
                      }}
                    />
                  </div>
                </div>
              </div>

              {eachOnto === "EACH" ? (
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="col-lg-4 ws-nowrap">Select Repeat Dates</div>
                  <div className="col-lg-8">
                    <div className="form-group">
                      <input
                        type="text"
                        // disabled
                        className="form-control"
                        value={selectedDates}
                        onClick={() => setOpenCalendar(true)}
                      />
                      <MultipleDatesPicker
                        open={openCalendar}
                        selectedDates={selectedDates.map((x) => {
                          return new Date(
                            `${moment().format("YYYY-MM")}-${moment(x).format(
                              "DD"
                            )}`
                          );
                        })}
                        onCancel={() => setOpenCalendar(false)}
                        onSubmit={(dates: any) => {
                          const startDate = moment()
                            .startOf("month")
                            .format("YYYY-MM-DD");
                          const endDate = moment()
                            .endOf("month")
                            .format("YYYY-MM-DD");

                          const newDates = dates
                            .map((x) => {
                              return `${moment().format("YYYY-MM")}-${moment(
                                x
                              ).format("DD")}`;
                            })
                            .filter(
                              (x) =>
                                new Date(x) >= new Date(startDate) &&
                                new Date(x) <= new Date(endDate)
                            );
                          setSelectedDates(_.uniq(newDates));
                          setOpenCalendar(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <div className="col-lg-4 ws-nowrap">Type</div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            name="repeatOn"
                            value={selectedType}
                            onChange={(e) => {
                              setSelectedType(e.target.value);
                            }}
                          >
                            <option value="FIRST">First</option>
                            <option value="SECOND">Second</option>
                            <option value="THIRD">Third</option>
                            <option value="FORTH">Forth</option>
                            <option value="FIFTH">Fifth</option>
                            <option value="LAST">Last</option>
                          </select>
                          <ExpandMoreRoundedIcon
                            style={{
                              position: "absolute",
                              right: "20px",
                              top: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <div className="col-lg-4 ws-nowrap">Day</div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            name="selectedDay"
                            value={selectedDay}
                            onChange={(e) => {
                              setSelectedDay(e.target.value);
                            }}
                          >
                            <option value="SUNDAY">Sunday</option>
                            <option value="MONDAY">Monday</option>
                            <option value="TUESDAY">Tuesday</option>
                            <option value="WEDNESDAY">Wednesday</option>
                            <option value="THURSDAY">Thursday</option>
                            <option value="FRIDAY">Friday</option>
                            <option value="SATURDAY">Saturday</option>
                            <option value="DAY">Day</option>
                          </select>
                          <ExpandMoreRoundedIcon
                            style={{
                              position: "absolute",
                              right: "20px",
                              top: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : null}

          {formik.values.repeat === "YEAR" ? (
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap">Select Months</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <SearchSelect
                      isMulti
                      options={monthOptions}
                      value={monthOptions.filter((x) => {
                        return selectedMonths.indexOf(x.value) !== -1;
                      })}
                      onChange={(opt: any) => {
                        if (opt) {
                          const val = opt.map((x: any) => x.value);
                          setSelectedMonths(val);
                        } else {
                          setSelectedMonths([]);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="col-lg-4 ws-nowrap">Type</div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <select
                          className="form-control"
                          name="repeatOn"
                          value={selectedType}
                          onChange={(e) => {
                            setSelectedType(e.target.value);
                          }}
                        >
                          <option value="FIRST">First</option>
                          <option value="SECOND">Second</option>
                          <option value="THIRD">Third</option>
                          <option value="FORTH">Forth</option>
                          <option value="FIFTH">Fifth</option>
                          <option value="LAST">Last</option>
                        </select>
                        <ExpandMoreRoundedIcon
                          style={{
                            position: "absolute",
                            right: "20px",
                            top: "10px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="col-lg-4 ws-nowrap">Day</div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <select
                          className="form-control"
                          name="selectedDay"
                          value={selectedDay}
                          onChange={(e) => {
                            setSelectedDay(e.target.value);
                          }}
                        >
                          <option value="SUNDAY">Sunday</option>
                          <option value="MONDAY">Monday</option>
                          <option value="TUESDAY">Tuesday</option>
                          <option value="WEDNESDAY">Wednesday</option>
                          <option value="THURSDAY">Thursday</option>
                          <option value="FRIDAY">Friday</option>
                          <option value="SATURDAY">Saturday</option>
                          <option value="DAY">Day</option>
                        </select>
                        <ExpandMoreRoundedIcon
                          style={{
                            position: "absolute",
                            right: "20px",
                            top: "10px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {sendNow === "true" ? null : (
            <div className="row">
              {formik.values.repeat === "WEEK" ? (
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="col-lg-4 ws-nowrap">Schedule Day</div>
                  <div className="col-lg-8">
                    <div className="form-group">
                      <SearchSelect
                        isMulti
                        options={dayOptions}
                        value={dayOptions.filter((x) => {
                          return (
                            formik.values.selectedDays.indexOf(x?.value) !== -1
                          );
                        })}
                        onChange={(opt: any) => {
                          if (opt) {
                            const fVal = opt.map((x: any) => x.value);
                            formik.setFieldValue("selectedDays", fVal);
                          } else {
                            formik.setFieldValue("selectedDays", []);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : formik.values.repeat === "DAY" ? (
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="col-lg-4 ws-nowrap">Schedule Time</div>
                  <div className="col-lg-8">
                    <div className="form-group">
                      <input
                        type="time"
                        className="form-control"
                        id="title"
                        name="startTime"
                        value={formik.values.startTime}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              ) : params.id &&
                (formik.values.repeat === "MONTH" ||
                  formik.values.repeat === "YEAR") ? (
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="col-lg-4 ws-nowrap ">Schedule Time</div>
                  <div className="col-lg-8">
                    <div className="form-group">
                      <input
                        type="time"
                        className="form-control"
                        id="title"
                        name="sendTime"
                        value={sendTimeValue}
                        onChange={(e) => {
                          setSendTimeValue(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : formik.values.repeat === "MONTH" ||
                formik.values.repeat === "YEAR" ? (
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="col-lg-4 ws-nowrap">Schedule Time</div>
                  <div className="col-lg-8">
                    <div className="form-group">
                      <input
                        type="time"
                        className="form-control"
                        id="title"
                        name="sendTime"
                        value={sendTimeValue}
                        onChange={(e) => {
                          setSendTimeValue(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="col-lg-4 ws-nowrap">Repeat</div>
                  <div className="col-lg-8">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="title"
                        disabled={Boolean(params.id)}
                        name="repeat"
                        value={formik.values.repeat}
                        onChange={formik.handleChange}
                      >
                        <option value="NEVER">Never</option>
                        <option value="DAY">Every Day</option>
                        <option value="WEEK">Every Week</option>
                        <option value="MONTH">Every Month</option>
                        <option value="YEAR">Every Year</option>
                      </select>
                      <ExpandMoreRoundedIcon
                        style={{
                          position: "absolute",
                          right: "20px",
                          top: "10px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {formik.values.repeat === "WEEK" ? (
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="col-lg-4 ws-nowrap ">Schedule Time</div>
                  <div className="col-lg-8">
                    <div className="form-group">
                      <input
                        type="time"
                        className="form-control"
                        id="title"
                        name="sendTime"
                        value={sendTimeValue}
                        onChange={(e) => {
                          setSendTimeValue(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : params.id ? null : formik.values.repeat === "NEVER" ? null : (
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="col-lg-4 ws-nowrap ">End Repeat Time</div>
                  <div className="col-lg-8">
                    <div className="form-group">
                      <input
                        type="datetime-local"
                        disabled={Boolean(params.id)}
                        className="form-control"
                        id="title"
                        name="endTime"
                        value={formik.values.endTime}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {params.id ? null : formik.values.repeat === "WEEK" ? (
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col-lg-4 ws-nowrap ">End Repeat Time</div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <input
                      type="datetime-local"
                      disabled={Boolean(params.id)}
                      className="form-control"
                      id="title"
                      name="endTime"
                      value={formik.values.endTime}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </CardBody>

        <CardFooter>
          <div className="row">
            <div className="col-lg-10">
              <div className="d-flex flex-wrap align-items-center justify-content-end">
                <div className="mt-4 mt-sm-0">
                  <button
                    className="btn btn-success mr-2 mx-sm-2"
                    onClick={() => {
                      setforFuture(false);
                      setTimeout(() => {
                        formik.handleSubmit();
                      }, 100);
                    }}
                  >
                    {params.id && isGrpMsg
                      ? "Save for this Reminder only"
                      : "Save"}
                  </button>
                </div>
                {params.id && isGrpMsg && (
                  <div className="mt-4 mt-sm-0">
                    <button
                      className="btn btn-success mr-2 mx-sm-2"
                      onClick={() => {
                        setforFuture(true);
                        setTimeout(() => {
                          formik.handleSubmit();
                        }, 100);
                      }}
                    >
                      Save for future reminders
                    </button>
                  </div>
                )}
                <div className="mt-4 mt-sm-0">
                  <Link className="btn btn-car-back" to="/message">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AddMessage;
